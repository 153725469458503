import { UIErrorMessageType } from "@im-fine/ui-libs";
import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Container,
    createStyles,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    makeStyles,
    Paper,
    Theme,
    Typography,
} from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import queryString from "query-string";
import React, { useCallback, useEffect, useState } from "react";
import { LocalizeContextProps, Translate, withLocalize } from "react-localize-redux";
import { StaticContext } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import client from "../api/client";
import AccountLoginSVG from "../assets/images/undraw_Login_v483.svg";
import ProfileAvatarSVG from "../assets/images/undraw_male_avatar_323b.svg";
import { Copyright } from "../components/Footer";
import { Top } from "../components/Top";
import StyledLink from "../navigation/StyledLink";
import { DEFAULT_REDIRECT_URL, QUERY_PARAM_REDIRECT_URL } from "../util/constants";
import { getErrorFromQueryString, getLoginURL } from "../util/routes";

export interface AccountsPageProps {}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.background.paper,
        },
        title: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(2),
        },
        icon: {
            height: "7rem",
            width: "100%",
            margin: theme.spacing(1),
        },
        errorText: {
            verticalAlign: "bottom",
            marginLeft: theme.spacing(0.5),
        },
        list: {
            width: "100%",
            // maxWidth: 360,
            backgroundColor: theme.palette.background.paper,
        },
        listItem: {
            color: theme.palette.text.primary,
            margin: theme.spacing(1),
            "&:hover": {
                color: theme.palette.text.primary,
            },
        },
        paper: {
            marginTop: theme.spacing(8),
            display: "flex",
            padding: theme.spacing(4, 4, 6, 4),

            flexDirection: "column",
            alignItems: "center",
        },
        email: {
            color: theme.palette.primary.main,
        },
        inline: {
            display: "inline",
        },
        progress: {
            margin: theme.spacing(2),
        },
    })
);

function AccountsPage(
    props: AccountsPageProps & RouteComponentProps<any, StaticContext, any> & LocalizeContextProps
) {
    const classes = useStyles(props);
    const urlParams = queryString.parse(window.location.search);

    const [authenticatedEmail, setAuthenticatedEmail] = useState<string>();
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState<UIErrorMessageType>(
        getErrorFromQueryString(props.translate)
    );
    const [checkedToken, setCheckedToken] = useState(false);

    const redirectURL = (urlParams[QUERY_PARAM_REDIRECT_URL] as string) || DEFAULT_REDIRECT_URL;
    const linkParams = {
        ...urlParams,
        [QUERY_PARAM_REDIRECT_URL]: encodeURIComponent(redirectURL),
    };

    const handleCurrentUserClick = () => {
        window.location.assign(redirectURL);
    };

    useEffect(() => {
        if (!checkedToken && !isLoading) {
            setLoading(true);
            client
                .getAuthenticatedUser()
                .then((user) => {
                    setAuthenticatedEmail(user.email);
                    setCheckedToken(true);
                })
                .catch((error) => {
                    console.error(error);
                    setAuthenticatedEmail(undefined);
                    setCheckedToken(true);

                    if ([401, 403].includes(error.statusCode)) {
                        window.location.assign(getLoginURL(linkParams));
                        return;
                    }

                    setError(error.message);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [checkedToken, isLoading, linkParams]);

    const handleLogout = useCallback(async () => {
        await client.logout();
        window.location.assign(getLoginURL(linkParams));
    }, [linkParams]);

    return (
        <Container maxWidth="sm">
            <Top />
            <Paper elevation={3} square={false} className={classes.paper}>
                <img alt="account" className={classes.icon} src={AccountLoginSVG} />
                <Typography component="h1" variant="h5" className={classes.title}>
                    <Translate id="account_page_title" />
                </Typography>
                {isLoading ? (
                    <CircularProgress className={classes.progress} />
                ) : error ? (
                    <Typography component="h4" color="error" hidden={error === undefined}>
                        <ErrorIcon />
                        <Box component="span" className={classes.errorText}>
                            {error}
                        </Box>
                    </Typography>
                ) : (
                    <>
                        <List className={classes.list}>
                            <ListItem
                                button
                                alignItems="flex-start"
                                className={classes.listItem}
                                onClick={handleCurrentUserClick}
                            >
                                <ListItemAvatar>
                                    <Avatar
                                        alt={props.translate("authenticated_email") as string}
                                        src={ProfileAvatarSVG}
                                    />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={props.translate("continue_as_current_user") as string}
                                    secondary={
                                        <>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                className={classes.email}
                                                color="textSecondary"
                                            >
                                                {authenticatedEmail}
                                            </Typography>
                                        </>
                                    }
                                />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                            <ListItem
                                button
                                alignItems="center"
                                className={classes.listItem}
                                component={StyledLink}
                                to={getLoginURL(linkParams)}
                            >
                                <ListItemAvatar>
                                    <Avatar alt={props.translate("login_another_user") as string}>
                                        <PersonOutlineIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={props.translate("login_another_user") as string}
                                />
                            </ListItem>
                        </List>
                        <Button color="primary" variant="outlined" onClick={handleLogout}>
                            <Translate id="logout" />
                        </Button>
                    </>
                )}
            </Paper>
            <Box mt={2}>
                <Copyright />
            </Box>
        </Container>
    );
}

export default withLocalize(AccountsPage);
