import {
    Box,
    Button,
    CircularProgress,
    Container,
    Grid,
    Paper,
    Theme,
    Typography,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import queryString from "query-string";
import React, { useState } from "react";
import { LocalizeContextProps, Translate, withLocalize } from "react-localize-redux";
import { StaticContext } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import client from "../api/client";
import MailSentSVG from "../assets/images/undraw_message_sent_1030.svg";
import { Copyright } from "../components/Footer";
import { Top } from "../components/Top";
import StyledLink from "../navigation/StyledLink";
import { QUERY_PARAM_EMAIL } from "../util/constants";
import { getLoginURL, getResetPasswordURL, URLParams } from "../util/routes";

export interface ResetPasswordMailSentPageProps {}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            body: {
                backgroundColor: theme.palette.common.white,
            },
        },
        paper: {
            marginTop: theme.spacing(8),
            display: "flex",
            padding: theme.spacing(5),

            flexDirection: "column",
            alignItems: "center",
        },
        image: {
            width: "100%",
            maxWidth: "20rem",
            margin: theme.spacing(1),
        },
        title: {
            ...theme.typography.h5,
            margin: theme.spacing(4),
        },
        text: {
            ...theme.typography.subtitle1,
            marginBottom: theme.spacing(4),
        },
        email: {
            ...theme.typography.subtitle1,
            fontWeight: "bold",
            color: theme.palette.primary.main,
        },
        resendButton: {
            textTransform: "capitalize",
            marginBottom: theme.spacing(2),
        },
    })
);

function ResetPasswordMailSentPage(
    props: ResetPasswordMailSentPageProps &
        RouteComponentProps<any, StaticContext, any> &
        LocalizeContextProps
) {
    const classes = useStyles();
    const urlParams = queryString.parse(window.location.search);
    const [mailSentFlag, setMailSentFlag] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const email = decodeURIComponent(urlParams[QUERY_PARAM_EMAIL] as string);

    const resendMailRequest = () => {
        setLoading(true);

        return client
            .sendSpecialistResetPasswordMail({
                email,
                redirectURL: `${window.location.origin}${getResetPasswordURL(
                    urlParams as URLParams
                )}`,
            })
            .then(() => setLoading(false))
            .catch(console.error);
    };

    const handleClick = async () => {
        if (mailSentFlag) {
            return;
        }

        setTimeout(() => setMailSentFlag(false), 60 * 1000);

        setMailSentFlag(true);
        resendMailRequest();
    };

    const button = isLoading ? (
        <CircularProgress size={24} color="inherit" />
    ) : (
        <Button
            disabled={mailSentFlag}
            fullWidth
            variant="outlined"
            color="primary"
            onClick={handleClick}
            className={classes.resendButton}
        >
            {mailSentFlag ? (
                <Translate id="password_reset_mail_sent_button" />
            ) : (
                <Translate id="email_resend" />
            )}
        </Button>
    );

    return (
        <Container maxWidth="sm">
            <Top />
            <Paper elevation={3} square={false} className={classes.paper}>
                <img alt="mail" className={classes.image} src={MailSentSVG} />
                <Typography component="h1" variant="h5" className={classes.title}>
                    <Translate id="password_reset_mail_sent_title" />
                </Typography>
                <Typography className={classes.text}>
                    <Translate
                        id="password_reset_mail_sent_text"
                        data={{
                            email: <span className={classes.email}>{}</span>,
                        }}
                    />
                </Typography>
                <Grid container direction="column" alignItems="center" justify="center">
                    <Grid item xs={12} sm={6}>
                        {button}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StyledLink to={getLoginURL(urlParams as URLParams)} variant="body2">
                            <Translate id="go_to_login" />
                        </StyledLink>
                    </Grid>
                </Grid>
            </Paper>
            <Box mt={2}>
                <Copyright />
            </Box>
        </Container>
    );
}

export default withLocalize(ResetPasswordMailSentPage);
