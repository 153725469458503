import { UIErrorMessageType } from "@im-fine/ui-libs";
import {
    Avatar,
    Box,
    Button,
    Container,
    Grid,
    Paper,
    TextField,
    Theme,
    Typography,
} from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import { createStyles, makeStyles } from "@material-ui/styles";
import queryString from "query-string";
import React, { useState } from "react";
import { LocalizeContextProps, Translate, withLocalize } from "react-localize-redux";
import { StaticContext } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import client from "../api/client";
import { enabledLanguages, getDefaultLanguage, LanguageType } from "../api/objects/language";
import AvatarSVG from "../assets/images/undraw_forgot_password_gi2d.svg";
import { Copyright } from "../components/Footer";
import { LanguageChanger } from "../components/LanguageChanger";
import { Top } from "../components/Top";
import StyledLink from "../navigation/StyledLink";
import {
    DEFAULT_REDIRECT_URL,
    QUERY_PARAM_EMAIL,
    QUERY_PARAM_LANGUAGE,
    QUERY_PARAM_REDIRECT_URL,
} from "../util/constants";
import {
    getErrorFromQueryString,
    getLanguageFromQueryString,
    getLoginURL,
    getResetPasswordMailSentURL,
    getResetPasswordURL,
} from "../util/routes";

export interface ResetPasswordRequestPageProps {}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            body: {
                backgroundColor: theme.palette.common.white,
            },
        },
        errorText: {
            verticalAlign: "bottom",
            marginLeft: theme.spacing(0.5),
        },
        paper: {
            marginTop: theme.spacing(8),
            display: "flex",
            padding: theme.spacing(5),

            flexDirection: "column",
            alignItems: "center",
        },
        avatar: {
            height: 70,
            width: 70,
            margin: theme.spacing(1),
        },
        form: {
            width: "100%", // Fix IE 11 issue.
            marginTop: theme.spacing(1),
        },
        submit: {
            margin: theme.spacing(3, 0, 2),
        },
        languageDropdown: {},
    })
);

function ResetPasswordRequestPage(
    props: ResetPasswordRequestPageProps &
        RouteComponentProps<any, StaticContext, any> &
        LocalizeContextProps
) {
    const classes = useStyles();
    const urlParams = queryString.parse(window.location.search);

    const redirectURL = (urlParams[QUERY_PARAM_REDIRECT_URL] as string) ?? DEFAULT_REDIRECT_URL;
    const [error, setError] = useState<UIErrorMessageType>(
        getErrorFromQueryString(props.translate)
    );
    const [errorEmail, setErrorEmail] = useState<UIErrorMessageType>(undefined);
    const [language, setLanguage] = useState(getLanguageFromQueryString() ?? getDefaultLanguage());
    const [email, setEmail] = useState(
        urlParams[QUERY_PARAM_EMAIL]
            ? decodeURIComponent(urlParams[QUERY_PARAM_EMAIL] as string)
            : ""
    );
    const [isLoading, setLoading] = useState(false);

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setErrorEmail(undefined);
        setEmail(event.target.value);
    };

    const clearErrors = () => {
        setErrorEmail(undefined);
        setError(undefined);
    };

    const handleLanguageChange = (newLanguage: LanguageType) => {
        setLanguage(newLanguage);
        props.setActiveLanguage(newLanguage);
    };

    const callResetRequest = async () => {
        try {
            setLoading(true);
            clearErrors();
            if (email === undefined || email.trim().length === 0) {
                setErrorEmail("Empty email.");
                return;
            }

            const resetPasswordParams = JSON.parse(JSON.stringify(linkParams));
            delete resetPasswordParams[QUERY_PARAM_EMAIL];

            await client.sendSpecialistResetPasswordMail({
                email: email.trim().toLowerCase(),
                redirectURL: `${window.location.origin}${getResetPasswordURL(resetPasswordParams)}`,
            });

            props.history.push(getResetPasswordMailSentURL(linkParams), {});
        } catch (error) {
            console.error(error);
            if (
                typeof error.message === "string" &&
                error.message.toLowerCase().includes("email")
            ) {
                setErrorEmail(error.message);
            } else {
                setError(error.message);
            }
        } finally {
            setLoading(false);
        }
    };

    const handleResetRequestClick = async (event: any) => {
        event.preventDefault();
        return await callResetRequest();
    };

    const linkParams: { [key: string]: string } = {
        ...urlParams,
        [QUERY_PARAM_LANGUAGE]: language,
    };

    if (email.trim().length > 0) {
        linkParams[QUERY_PARAM_EMAIL] = encodeURIComponent(email);
    }

    if (redirectURL !== DEFAULT_REDIRECT_URL) {
        linkParams[QUERY_PARAM_REDIRECT_URL] = redirectURL;
    }

    return (
        <Container maxWidth="xs">
            <Top />
            <Paper elevation={3} square={false} className={classes.paper}>
                <Avatar className={classes.avatar} src={AvatarSVG}></Avatar>
                <Typography component="h1" variant="h5">
                    <Translate id="password_reset_request_title" />
                </Typography>
                <Grid container justify="center" direction="column" alignContent="center">
                    <LanguageChanger
                        id="language"
                        enabledLanguages={enabledLanguages}
                        selectedLanguage={language}
                        onChange={handleLanguageChange}
                    />
                </Grid>
                <Typography component="h4" color="error" hidden={error === undefined}>
                    <ErrorIcon />
                    <Box component="span" className={classes.errorText}>
                        {error}
                    </Box>
                </Typography>
                <form className={classes.form} noValidate>
                    <TextField
                        error={errorEmail !== undefined}
                        variant="standard"
                        margin="normal"
                        helperText={errorEmail || ""}
                        required
                        fullWidth
                        value={email}
                        id="email"
                        label={props.translate("email_address") as string}
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={handleEmailChange}
                        disabled={isLoading}
                    />
                    <Button
                        disabled={isLoading}
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleResetRequestClick}
                    >
                        <Translate id="password_reset_request_submit" />
                    </Button>
                    <Grid container>
                        <Grid item xs={12}>
                            <StyledLink to={getLoginURL(linkParams)} variant="body2">
                                <Translate id="password_reset_request_cancel" />
                            </StyledLink>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
            <Box mt={2}>
                <Copyright />
            </Box>
        </Container>
    );
}

export default withLocalize(ResetPasswordRequestPage);
