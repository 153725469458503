import { createStyles, Divider, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textCenter: {
            textAlign: "center",
        },
    })
);

export interface OrDividerRowProps {}

function OrDividerRow(props: OrDividerRowProps & LocalizeContextProps) {
    const classes = useStyles();

    return (
        <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
            <Grid item xs={5}>
                <Divider />
            </Grid>
            <Grid item xs={2}>
                <Typography className={classes.textCenter}>
                    {(props.translate("or") as string).toLowerCase()}
                </Typography>
            </Grid>
            <Grid item xs={5}>
                <Divider />
            </Grid>
        </Grid>
    );
}

export default withLocalize(OrDividerRow);
