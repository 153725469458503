import * as React from "react";
import { LanguageType, mapLanguageToDisplayText } from "../api/objects/language";
import { Button, Menu, MenuItem, Box } from "@material-ui/core";

export interface LanguageChangerProps {
    selectedLanguage: LanguageType;
    enabledLanguages: LanguageType[];
    id?: string;
    linkClassNames?: string;
    dropdownClassNames?: string;
    containerClassNames?: string;
    title?: string;
    onChange: (newLanguage: LanguageType) => void;
}

export const mapLanguageToFlagElement = (language: LanguageType) => {
    let countryName = language.toString();
    switch (language) {
        case "en":
            countryName = "gb";
    }
    return <i className={`flag-icon flag-icon-${countryName}`}></i>;
};

export const LanguageChanger = (props: LanguageChangerProps) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const id = props.id || "language-changer__dropdown";
    const linkClassNames = props.linkClassNames || "";
    const dropdownClassNames = props.dropdownClassNames || "";
    const containerClassNames = props.containerClassNames || "";
    const title = props.title || "Change language";

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const languageItems = props.enabledLanguages.map((language) => (
        <MenuItem
            key={language}
            onClick={() => {
                props.onChange(language);
                handleClose();
            }}
        >
            {mapLanguageToFlagElement(language)}
            <Box ml={1} component="span">
                {mapLanguageToDisplayText(language)}
            </Box>
        </MenuItem>
    ));

    return (
        <div className={containerClassNames}>
            <Button
                className={linkClassNames}
                aria-controls={id}
                aria-haspopup="true"
                title={title}
                onClick={handleClick}
            >
                {mapLanguageToFlagElement(props.selectedLanguage)}
                <Box ml={1} component="span">
                    {mapLanguageToDisplayText(props.selectedLanguage)}
                </Box>
            </Button>
            <Menu
                id={id}
                anchorEl={anchorEl}
                className={dropdownClassNames}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {languageItems}
            </Menu>
        </div>
    );
};
