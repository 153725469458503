import queryString from "query-string";
import { TranslateFunction } from "react-localize-redux";
import { LanguageType } from "../api/objects/language";
import { QUERY_PARAM_ERROR, QUERY_PARAM_ERROR_ID, QUERY_PARAM_LANGUAGE } from "./constants";
import { getEnvVariable } from "./env";

export type URLParams = { [key: string]: string | string[] };

export const LOGIN_URL = "/login";
export const SIGNUP_URL = "/signup";
export const ACCOUNTS_URL = "/accounts";
export const UNAUTHORIZED_URL = "/unauthorized";
export const RESET_REQUEST_URL = "/reset-request";
export const RESET_PASSWORD_MAIL_SENT_URL = "/reset-request-mail-sent";
export const RESET_PASSWORD_URL = "/reset-password";

export const getBasePath = (): string => {
    let basePath = getEnvVariable("REACT_APP_BASE_PATH").trim();

    if (basePath === "/" || basePath === "") {
        return "";
    }

    if (!basePath.startsWith("/")) {
        basePath = "/" + basePath;
    }

    if (basePath.endsWith("/")) {
        basePath = basePath.substr(0, basePath.length - 1);
    }

    return basePath; // TODO #translation
};

export const getURLWithParams = (url: string, urlParams?: URLParams): string => {
    if (url.trim().length > 1 && url.endsWith("/")) {
        url = url.substr(0, url.length - 1);
    }

    return `${getBasePath()}${url}${urlParams ? `?${queryString.stringify(urlParams)}` : ""}`;
};

export const getLoginURL = (urlParams?: URLParams): string =>
    getURLWithParams(LOGIN_URL, urlParams);

export const getSignupURL = (urlParams?: URLParams): string =>
    getURLWithParams(SIGNUP_URL, urlParams);

export const getAccountsURL = (urlParams?: URLParams): string =>
    getURLWithParams(ACCOUNTS_URL, urlParams);

export const getUnauthorizedURL = (urlParams?: URLParams): string =>
    getURLWithParams(UNAUTHORIZED_URL, urlParams);

export const getResetRequestURL = (urlParams?: URLParams): string =>
    getURLWithParams(RESET_REQUEST_URL, urlParams);

export const getResetPasswordMailSentURL = (urlParams?: URLParams): string =>
    getURLWithParams(RESET_PASSWORD_MAIL_SENT_URL, urlParams);

export const getResetPasswordURL = (urlParams?: URLParams): string =>
    getURLWithParams(RESET_PASSWORD_URL, urlParams);

export const getLanguageFromQueryString = (): LanguageType | undefined => {
    const urlParams = queryString.parse(window.location.search);
    return urlParams[QUERY_PARAM_LANGUAGE]
        ? (urlParams[QUERY_PARAM_LANGUAGE] as LanguageType)
        : undefined;
};

export const getErrorFromQueryString = (translate: TranslateFunction): string | undefined => {
    const urlParams = queryString.parse(window.location.search);
    return (
        (urlParams[QUERY_PARAM_ERROR] as string) ??
        (urlParams[QUERY_PARAM_ERROR_ID]
            ? (translate(urlParams[QUERY_PARAM_ERROR_ID] as string) as string)
            : undefined)
    );
};
