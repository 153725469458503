export type LanguageType = "en" | "ro";

export const DEFAULT_LANGUAGE: LanguageType = "ro";

export type TextWithLanguage = { [key in LanguageType]: string };

export const mapLanguageToDisplayText = (language: LanguageType) => {
    switch (language) {
        case "en":
            return "English";
        case "ro":
            return "Română";
        default:
            throw new Error(`Unhandled language type ${JSON.stringify(language)}`);
    }
};

export const emptyTextWithLanguages = { [DEFAULT_LANGUAGE]: "" };

export const enabledLanguages: LanguageType[] = ["en", "ro"];

export const getBrowserLanguage = () => {
    return window.navigator.language.split("-")[0] as LanguageType;
};

export const getDefaultLanguage = () => {
    return /*getBrowserLanguage() ||*/ DEFAULT_LANGUAGE as LanguageType;
};

export const getFirstLanguageAvailableFromTextObject = (
    textAllLanguages: TextWithLanguage
): LanguageType | undefined => {
    let selectedLanguage: LanguageType | undefined;

    for (const [language, text] of Object.entries(textAllLanguages)) {
        if (text && text.trim().length > 0) {
            selectedLanguage = language as LanguageType;
            break;
        }
    }

    return selectedLanguage;
};
