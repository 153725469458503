import { UIErrorMessageType } from "@im-fine/ui-libs";
import {
    Box,
    Button,
    Checkbox,
    Container,
    createStyles,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    InputAdornment,
    Link,
    makeStyles,
    Paper,
    TextField,
    Theme,
    Typography,
} from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import queryString from "query-string";
import React, { useCallback, useState } from "react";
import { LocalizeContextProps, Translate, withLocalize } from "react-localize-redux";
import { StaticContext } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import client from "../api/client";
import { enabledLanguages, getDefaultLanguage, LanguageType } from "../api/objects/language";
import { Gender } from "../api/objects/User";
import NewSpecialistIcon from "../assets/images/undraw_windy_day_x63l.svg";
import AppleLoginButton from "../components/AppleLoginButton";
import { Copyright } from "../components/Footer";
import { GenderChanger } from "../components/GenderChanger";
import GoogleLoginButton from "../components/GoogleLoginButton";
import { LanguageChanger } from "../components/LanguageChanger";
import OrDividerRow from "../components/OrDividerRow";
import { Top } from "../components/Top";
import StyledLink from "../navigation/StyledLink";
import {
    DEFAULT_REDIRECT_URL,
    PASSWORD_MAX_LENGTH,
    PASSWORD_MIN_LENGTH,
    QUERY_PARAM_EMAIL,
    QUERY_PARAM_LANGUAGE,
    QUERY_PARAM_REDIRECT_URL,
} from "../util/constants";
import { getErrorFromQueryString, getLanguageFromQueryString, getLoginURL } from "../util/routes";
import { checkPasswordLength } from "../util/utils";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            body: {
                backgroundColor: theme.palette.common.white,
            },
        },
        errorText: {
            verticalAlign: "bottom",
            marginLeft: theme.spacing(0.5),
        },
        paper: {
            [theme.breakpoints.up(1920)]: {
                marginTop: theme.spacing(25),
            },
            [theme.breakpoints.up(1367)]: {
                marginTop: theme.spacing(15),
            },
            [theme.breakpoints.down(1366)]: {
                marginTop: theme.spacing(1),
            },
            display: "flex",
            padding: theme.spacing(5),
            flexDirection: "column",
            alignItems: "center",
        },
        title: {
            marginBottom: theme.spacing(2),
        },
        icon: {
            height: "10rem",
            width: "100%",
            margin: theme.spacing(1),
        },
        form: {
            width: "100%", // Fix IE 11 issue.
            marginTop: theme.spacing(0.5),
        },
        languageDropdown: {
            textAlign: "right",
        },
        genderDropdown: {
            textAlign: "right",
        },
        submit: {
            margin: theme.spacing(3, 0, 2),
        },
        link: {
            textDecoration: "none",
            "&:focus, &:hover, &:visited, &:link, &:active": {
                textDecoration: "none",
            },
        },
        socialLoginContainer: {
            marginBottom: theme.spacing(2),
        },
        textCenter: {
            textAlign: "center",
        },
    })
);

export interface SignupPageProps {}

function SignupPage(
    props: SignupPageProps & RouteComponentProps<any, StaticContext, any> & LocalizeContextProps
) {
    const classes = useStyles();
    const urlParams = queryString.parse(window.location.search);

    const redirectURL = (urlParams[QUERY_PARAM_REDIRECT_URL] as string) ?? DEFAULT_REDIRECT_URL;
    const [error, setError] = useState<UIErrorMessageType>(
        getErrorFromQueryString(props.translate)
    );
    const [email, setEmail] = useState(
        urlParams[QUERY_PARAM_EMAIL]
            ? decodeURIComponent(urlParams[QUERY_PARAM_EMAIL] as string)
            : ""
    );
    const [errorEmail, setErrorEmail] = useState<UIErrorMessageType>(undefined);
    const [errorPassword, setErrorPassword] = useState<UIErrorMessageType>(undefined);
    const [language, setLanguage] = useState(getLanguageFromQueryString() ?? getDefaultLanguage());
    const [gender, setGender] = useState(Gender.UNSPECIFIED);
    const [password, setPassword] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [userAgree, setUserAgree] = useState(false);

    const linkParams: { [key: string]: string } = {
        ...urlParams,
        [QUERY_PARAM_LANGUAGE]: language,
    };

    if (email.trim().length > 0) {
        linkParams[QUERY_PARAM_EMAIL] = encodeURIComponent(email);
    }

    if (redirectURL !== DEFAULT_REDIRECT_URL) {
        linkParams[QUERY_PARAM_REDIRECT_URL] = redirectURL;
    }

    const clearErrors = () => {
        setErrorEmail(undefined);
        setErrorPassword(undefined);
        setError(undefined);
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setErrorEmail(undefined);
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        setErrorPassword(undefined);
        setPassword(newValue);
        if (!checkPasswordLength(newValue)) {
            setErrorPassword(
                props.translate("incorrect_password_length", {
                    min: PASSWORD_MIN_LENGTH,
                    max: PASSWORD_MAX_LENGTH,
                }) as string
            );
        }
    };

    const handleLanguageChange = (newLanguage: LanguageType) => {
        setLanguage(newLanguage);
        props.setActiveLanguage(newLanguage);
    };

    const handleGenderChange = (newGender: Gender) => {
        setGender(newGender);
    };

    const handleGoogleLoginSuccess = useCallback(
        async (googleUser: gapi.auth2.GoogleUser) => {
            setLoading(true);

            // const authData = googleUser.getAuthResponse(true);
            const authData = googleUser.getAuthResponse();

            try {
                clearErrors();
                /*const result = */ await client.loginOrSignUpWithGoogleSpecialist({
                    googleIdToken: authData.id_token,
                });
                // console.debug("result", result);

                window.location.assign(redirectURL);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        },
        [redirectURL]
    );

    const handleSignupClick = useCallback(
        async (event: any) => {
            try {
                event.preventDefault();
                setLoading(true);
                clearErrors();
                if (email === undefined || email.trim().length === 0) {
                    setErrorEmail("Empty email.");
                    return false;
                }
                if (password === undefined || password.length === 0) {
                    setErrorPassword("Empty password.");
                    return false;
                }

                await client.createUserSpecialist({
                    email: email.trim().toLowerCase(),
                    password,
                    language,
                    gender,
                });

                window.location.assign(redirectURL);
            } catch (error) {
                console.error(error);
                if (
                    typeof error.message === "string" &&
                    error.message.toLowerCase().includes("email")
                ) {
                    setErrorEmail(error.message);
                } else if (
                    typeof error.message === "string" &&
                    error.message.toLowerCase().includes("password")
                ) {
                    setErrorPassword(error.message);
                } else {
                    setError(error.message);
                }
            } finally {
                setLoading(false);
            }
        },
        [email, gender, language, password, redirectURL]
    );

    return (
        <Container maxWidth="sm">
            <Top />
            <Paper elevation={3} square={false} className={classes.paper}>
                <img alt="new account" className={classes.icon} src={NewSpecialistIcon} />
                <Typography className={classes.title} component="h2" variant="h5">
                    <Translate id="signup_title" />
                </Typography>
                <Typography component="h4" color="error" hidden={error === undefined}>
                    <ErrorIcon />
                    <Box component="span" className={classes.errorText}>
                        {error}
                    </Box>
                </Typography>
                <form className={classes.form} noValidate>
                    <TextField
                        error={errorEmail !== undefined}
                        variant="standard"
                        margin="normal"
                        value={email}
                        helperText={errorEmail || ""}
                        required
                        fullWidth
                        id="email"
                        label={props.translate("email_address") as string}
                        name="email"
                        autoComplete="email"
                        type="email"
                        autoFocus
                        onChange={handleEmailChange}
                        disabled={isLoading}
                    />
                    <FormControl fullWidth className={classes.form}>
                        <TextField
                            helperText={errorPassword || ""}
                            variant="standard"
                            margin="normal"
                            required
                            label={props.translate("password") as string}
                            fullWidth
                            error={errorPassword !== undefined}
                            name="password"
                            type={showPassword ? "text" : "password"}
                            id="password"
                            autoComplete="current-password"
                            onChange={handlePasswordChange}
                            disabled={isLoading}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onMouseDown={() => setShowPassword(true)}
                                            onMouseUp={() => setShowPassword(false)}
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormControl>
                    <Grid
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                        className={classes.form}
                    >
                        <Grid item xs={6}>
                            <FormLabel htmlFor="gender">
                                <Translate id="gender" />
                            </FormLabel>
                        </Grid>
                        <Grid item xs={6} className={classes.genderDropdown}>
                            <GenderChanger
                                id="gender"
                                enabledGenders={Object.values(Gender)}
                                selectedGender={gender}
                                onChange={handleGenderChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                        className={classes.form}
                    >
                        <Grid item xs={6}>
                            <FormLabel htmlFor="language">
                                <Translate id="language" />
                            </FormLabel>
                        </Grid>
                        <Grid item xs={6} className={classes.languageDropdown}>
                            <LanguageChanger
                                id="language"
                                enabledLanguages={enabledLanguages}
                                selectedLanguage={language}
                                onChange={handleLanguageChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" alignItems="center">
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={userAgree}
                                        onChange={() => setUserAgree((prevState) => !prevState)}
                                        color="primary"
                                    />
                                }
                                label={
                                    <>
                                        <Translate id="i_agree_with_the" />
                                        <Link
                                            href="https://im-fine.app/legal/platform/terms-and-conditions.html"
                                            target="_blank"
                                            rel="noopener"
                                            className={classes.link}
                                        >
                                            <Translate id="terms_and_conditions" />
                                        </Link>
                                        <Translate id="and" />
                                        <Link
                                            href="https://im-fine.app/legal/platform/privacy-policy.html"
                                            target="_blank"
                                            rel="noopener"
                                            className={classes.link}
                                        >
                                            <Translate id="privacy_policy" />
                                        </Link>
                                    </>
                                }
                            />
                        </Grid>
                    </Grid>
                    <Button
                        disabled={isLoading || !userAgree}
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        className={classes.submit}
                        onClick={handleSignupClick}
                    >
                        <Translate id="signup_submit" />
                    </Button>

                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        spacing={1}
                        className={classes.socialLoginContainer}
                    >
                        <Grid item xs={12} style={{ width: "100%" }}>
                            <OrDividerRow />
                        </Grid>
                        <Grid item style={{ width: "100%" }}>
                            <GoogleLoginButton
                                // isLoading={isLoading}
                                disabled={isLoading}
                                onSuccess={handleGoogleLoginSuccess}
                            />
                        </Grid>
                        <Grid item style={{ width: "100%", marginTop: 16 }}>
                            <AppleLoginButton
                                redirectURI={redirectURL}
                                color="white"
                                border
                                type="sign-in"
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={12} className={classes.textCenter}>
                            <StyledLink to={getLoginURL(linkParams)} variant="body2">
                                <Translate id="signup_login_link" />
                            </StyledLink>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
            <Box mt={2}>
                <Copyright />
            </Box>
        </Container>
    );
}

export default withLocalize(SignupPage);
