import { UIErrorMessageType } from "@im-fine/ui-libs";
import {
    Avatar,
    Box,
    Button,
    Container,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    Link,
    Paper,
    TextField,
    Theme,
    Typography,
} from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { createStyles, makeStyles } from "@material-ui/styles";
import queryString from "query-string";
import React, { useCallback, useState } from "react";
import { LocalizeContextProps, Translate, withLocalize } from "react-localize-redux";
import { StaticContext } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import client from "../api/client";
import { enabledLanguages, getDefaultLanguage, LanguageType } from "../api/objects/language";
import AvatarSVG from "../assets/images/undraw_male_avatar_323b.svg";
import AppleLoginButton from "../components/AppleLoginButton";
import { Copyright } from "../components/Footer";
import GoogleLoginButton from "../components/GoogleLoginButton";
import { LanguageChanger } from "../components/LanguageChanger";
import OrDividerRow from "../components/OrDividerRow";
import { Top } from "../components/Top";
import StyledLink from "../navigation/StyledLink";
import {
    DEFAULT_REDIRECT_URL,
    QUERY_PARAM_EMAIL,
    QUERY_PARAM_LANGUAGE,
    QUERY_PARAM_REDIRECT_URL,
} from "../util/constants";
import {
    getErrorFromQueryString,
    getLanguageFromQueryString,
    getResetRequestURL,
    getSignupURL,
} from "../util/routes";

export interface LoginPageProps {}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            body: {
                backgroundColor: theme.palette.common.white,
            },
        },
        errorText: {
            verticalAlign: "bottom",
            marginLeft: theme.spacing(0.5),
        },
        paper: {
            marginTop: theme.spacing(8),
            display: "flex",
            padding: theme.spacing(5),

            flexDirection: "column",
            alignItems: "center",
        },
        avatar: {
            height: 70,
            width: 70,
            margin: theme.spacing(1),
        },
        form: {
            width: "100%", // Fix IE 11 issue.
            marginTop: theme.spacing(1),
        },
        submit: {
            margin: theme.spacing(3, 0, 2),
        },
        languageDropdown: {},
        link: {
            textDecoration: "none",
            "&:focus, &:hover, &:visited, &:link, &:active": {
                textDecoration: "none",
            },
        },
        termsContainer: {
            height: "100%",
        },
        termsDivider: {
            marginLeft: 5,
            marginRight: 5,
            minHeight: theme.typography.fontSize,
        },
        socialLoginContainer: {
            marginBottom: theme.spacing(2),
        },
        textCenter: {
            textAlign: "center",
        },
    })
);

function LoginPage(
    props: LoginPageProps & RouteComponentProps<any, StaticContext, any> & LocalizeContextProps
) {
    const classes = useStyles();
    const urlParams = queryString.parse(window.location.search);

    const redirectURL = decodeURIComponent(
        (urlParams[QUERY_PARAM_REDIRECT_URL] as string) ?? DEFAULT_REDIRECT_URL
    );
    const [error, setError] = useState<UIErrorMessageType>(
        getErrorFromQueryString(props.translate)
    );
    const [errorEmail, setErrorEmail] = useState<UIErrorMessageType>(undefined);
    const [errorPassword, setErrorPassword] = useState<UIErrorMessageType>(undefined);
    const [language, setLanguage] = useState(getLanguageFromQueryString() ?? getDefaultLanguage());
    const [email, setEmail] = useState(
        urlParams[QUERY_PARAM_EMAIL]
            ? decodeURIComponent(urlParams[QUERY_PARAM_EMAIL] as string)
            : ""
    );
    const [password, setPassword] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setErrorEmail(undefined);
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setErrorPassword(undefined);
        setPassword(event.target.value);
    };

    const clearErrors = () => {
        setErrorEmail(undefined);
        setErrorPassword(undefined);
        setError(undefined);
    };

    const handleLanguageChange = (newLanguage: LanguageType) => {
        setLanguage(newLanguage);
        props.setActiveLanguage(newLanguage);
    };

    const callLogin = async () => {
        try {
            setLoading(true);
            clearErrors();
            if (email === undefined || email.trim().length === 0) {
                setErrorEmail("Empty email.");
                return;
            }
            if (password === undefined || password.length === 0) {
                setErrorPassword("Empty password.");
                return;
            }

            await client.login(email.trim().toLowerCase(), password);

            window.location.assign(redirectURL);
        } catch (error) {
            console.error(error);
            if (
                typeof error.message === "string" &&
                error.message.toLowerCase().includes("email")
            ) {
                setErrorEmail(error.message);
            } else if (
                typeof error.message === "string" &&
                error.message.toLowerCase().includes("password")
            ) {
                setErrorPassword(error.message);
            } else {
                setError(error.message);
            }
        } finally {
            setLoading(false);
        }
    };

    const handleLoginClick = async (event: any) => {
        event.preventDefault();
        return await callLogin();
    };

    const handleGoogleLoginSuccess = useCallback(
        async (googleUser: gapi.auth2.GoogleUser) => {
            setLoading(true);

            // const authData = googleUser.getAuthResponse(true);
            const authData = googleUser.getAuthResponse(true);
            console.debug("authData", authData);

            (window as any).authData = authData;

            try {
                clearErrors();
                /*const result =*/ await client.loginOrSignUpWithGoogleSpecialist({
                    googleIdToken: authData.id_token,
                });
                // console.debug("result", result);

                window.location.assign(redirectURL);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        },
        [redirectURL]
    );

    const linkParams: { [key: string]: string } = {
        ...urlParams,
        [QUERY_PARAM_LANGUAGE]: language,
    };

    if (email.trim().length > 0) {
        linkParams[QUERY_PARAM_EMAIL] = encodeURIComponent(email);
    }

    if (redirectURL !== DEFAULT_REDIRECT_URL) {
        linkParams[QUERY_PARAM_REDIRECT_URL] = redirectURL;
    }

    return (
        <Container maxWidth="xs">
            <Top />
            <Paper elevation={3} square={false} className={classes.paper}>
                <Avatar className={classes.avatar} src={AvatarSVG}></Avatar>
                <Typography component="h1" variant="h5">
                    <Translate id="login_title" />
                </Typography>
                <Grid container justify="center" direction="column" alignContent="center">
                    <LanguageChanger
                        id="language"
                        enabledLanguages={enabledLanguages}
                        selectedLanguage={language}
                        onChange={handleLanguageChange}
                    />
                </Grid>
                <Typography component="h4" color="error" hidden={error === undefined}>
                    <ErrorIcon />
                    <Box component="span" className={classes.errorText}>
                        {error}
                    </Box>
                </Typography>
                <form className={classes.form} noValidate>
                    <TextField
                        error={errorEmail !== undefined}
                        variant="standard"
                        margin="normal"
                        helperText={errorEmail || ""}
                        required
                        fullWidth
                        value={email}
                        id="email"
                        label={props.translate("email_address") as string}
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={handleEmailChange}
                        disabled={isLoading}
                    />
                    <FormControl fullWidth className={classes.form}>
                        <TextField
                            helperText={errorPassword || ""}
                            variant="standard"
                            margin="normal"
                            required
                            label={props.translate("password") as string}
                            fullWidth
                            error={errorPassword !== undefined}
                            name="password"
                            type={showPassword ? "text" : "password"}
                            id="password"
                            autoComplete="current-password"
                            onChange={handlePasswordChange}
                            disabled={isLoading}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            // aria-label="toggle password visibility"
                                            onClick={() =>
                                                setShowPassword((prevValue) => !prevValue)
                                            }
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormControl>
                    <Grid container>
                        <Grid item xs>
                            <Box mt={1}>
                                <StyledLink to={getResetRequestURL(linkParams)} variant="body2">
                                    <Translate id="forgotten_password_link" />
                                </StyledLink>
                            </Box>
                        </Grid>
                    </Grid>
                    <Button
                        disabled={isLoading}
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleLoginClick}
                    >
                        <Translate id="login_submit" />
                    </Button>

                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        spacing={1}
                        className={classes.socialLoginContainer}
                    >
                        <Grid item xs={12} style={{ width: "100%" }}>
                            <OrDividerRow />
                        </Grid>
                        <Grid item style={{ width: "100%" }}>
                            <GoogleLoginButton
                                // isLoading={isLoading}
                                disabled={isLoading}
                                onSuccess={handleGoogleLoginSuccess}
                            />
                        </Grid>
                        <Grid item style={{ width: "100%", marginTop: 16 }}>
                            <AppleLoginButton
                                redirectURI={redirectURL}
                                color="white"
                                border
                                type="continue"
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={12} className={classes.textCenter}>
                            <StyledLink to={getSignupURL(linkParams)} variant="body2">
                                <Translate id="login_signup_link" />
                            </StyledLink>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
            <Box mt={2}>
                <Copyright />
                <Grid container direction="row" justify="center" className={classes.termsContainer}>
                    <Link
                        href="https://im-fine.app/legal/platform/terms-and-conditions.html"
                        target="_blank"
                        rel="noopener"
                        className={classes.link}
                    >
                        <Translate id="terms_and_conditions" />
                    </Link>
                    <Divider orientation="vertical" className={classes.termsDivider} />
                    <Link
                        href="https://im-fine.app/legal/platform/privacy-policy.html"
                        target="_blank"
                        rel="noopener"
                        className={classes.link}
                    >
                        <Translate id="privacy_policy" />
                    </Link>
                </Grid>
            </Box>
        </Container>
    );
}

export default withLocalize(LoginPage);
