import { ButtonProps, createStyles, makeStyles, Theme } from "@material-ui/core";
import React, { useEffect } from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import client from "../api/client";
import { ProviderType } from "../api/objects/ProviderType";
import { UserRole } from "../api/objects/User";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            padding: 0,
            border: "2px solid black",
            "&:hover": {
                backgroundColor: "initial",
            },
        },
        img: {},
        root: {
            height: 45,
            cursor: "pointer",
            outline: "none !important",
            "& > div": {
                maxWidth: "none !important",
                outline: "none !important",
            },
            "& > div > div": {
                outline: "none !important",
            },
        },
    })
);

const scopes = ["name", "email"];

export interface AppleLoginButtonProps {
    redirectURI: string;
    // height?: number;
    // width?: number;
    color?: "white" | "black";
    border?: boolean;
    type?: "sign-in" | "continue";
    borderRadius?: number; // 0 - 50
    scale?: number; // 1 - 6
    ButtonProps?: Partial<ButtonProps>;
}

// https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js/incorporating_sign_in_with_apple_into_other_platforms
function AppleLoginButton({
    activeLanguage,
    redirectURI,
    border,
    borderRadius = 4,
    // height = 30,
    // width = 140,
    color = "black",
    type = "sign-in",
    scale = 1,
}: AppleLoginButtonProps & LocalizeContextProps) {
    const classes = useStyles();

    // let locale = window.navigator.language;
    // switch (activeLanguage.code) {
    //     case "en":
    //         locale = "en_US";
    //         break;
    //     default:
    //         locale = `${activeLanguage.code.toLowerCase()}_${activeLanguage.code.toUpperCase()}`;
    // }

    useEffect(() => {
        client
            .getAuthorizationURLForProvider(ProviderType.APPLE, {
                scopes,
                redirectURI,
                userRole: UserRole.ROLE_SPECIALIST,
            })
            .then((response) => {
                const url = new URL(response.link);

                (window as any).AppleID.auth.init({
                    clientId: url.searchParams.get("client_id"),
                    scope: url.searchParams.get("scope")?.replace("+", " ") ?? scopes.join(" "),
                    redirectURI: url.searchParams.get("redirect_uri"),
                    state: url.searchParams.get("state"),
                    nonce: window.location.href,
                    usePopup: false, //or false defaults to false
                });

                // setTimeout(() => (window as any).AppleID.auth.signIn().then(console.debug), 5000);
            });
        const handleSuccess = (data: any) => {
            console.debug("SUCCESS APPLE", data);
        };

        const handleError = (error: any) => {
            console.error("ERROR APPLE", error);
        };

        //Listen for authorization success
        window.document.addEventListener("AppleIDSignInOnSuccess", handleSuccess);
        //Listen for authorization failures
        window.document.addEventListener("AppleIDSignInOnFailure", handleError);

        return () => {
            window.document.removeEventListener("AppleIDSignInOnSuccess", handleSuccess);
            window.document.removeEventListener("AppleIDSignInOnFailure", handleError);
        };
    }, [redirectURI]);

    return (
        <div
            id="appleid-signin"
            data-color={color}
            data-border={border ? "true" : "false"}
            data-border-radius={borderRadius}
            data-type={type}
            data-scale={scale}
            // data-locale={activeLanguage}
            className={classes.root + " MuiButton-contained"}
        ></div>
    );
}

export default withLocalize(AppleLoginButton);
