import { translationTransform } from "@im-fine/ui-libs";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { enabledLanguages, getDefaultLanguage } from "./api/objects/language";
import { TranslationExportType } from "./api/translation/APITranslation";
import { TranslationsLanguageKeyObject } from "./api/translation/objects/Translation";
import translationClient from "./api/translationClient";
import "./App.css";
import cachedTranslations from "./assets/translations/cached.translations.json";
import FullPageLoading from "./components/FullPageLoading";
import AccountsPage from "./pages/AccountsPage";
import LoginPage from "./pages/LoginPage";
import ResetPasswordMailSentPage from "./pages/ResetPasswordMailSentPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import ResetPasswordRequestPage from "./pages/ResetPasswordRequestPage";
import SignupPage from "./pages/SignupPage";
import UnauthorizedPage from "./pages/UnauthorizedPage";
import theme from "./theme/theme";
import {
    getAccountsURL,
    getLanguageFromQueryString,
    getLoginURL,
    getResetPasswordMailSentURL,
    getResetPasswordURL,
    getResetRequestURL,
    getSignupURL,
    getUnauthorizedURL,
} from "./util/routes";

interface AppProps {}

const App = ({ initialize, addTranslation, ...props }: AppProps & LocalizeContextProps) => {
    const [isLoading, setLoading] = useState(true);
    const [isDataLoaded, setDataLoaded] = useState(false);

    useEffect(() => {
        if (!isDataLoaded) {
            setLoading(true);
            setDataLoaded(true);

            // console.debug("CachedTranslations", cachedTranslations);
            initialize({
                languages: enabledLanguages,
                options: {
                    renderToStaticMarkup,
                    defaultLanguage: getLanguageFromQueryString() ?? getDefaultLanguage(),
                    ignoreTranslateChildren: true,
                },
                translation: translationTransform(
                    cachedTranslations.translations,
                    enabledLanguages
                ),
            });

            const getTranslations = async () => {
                const translationDataFromAPI = (await translationClient.getTranslations(
                    TranslationExportType.BY_KEY_OBJECT
                )) as TranslationsLanguageKeyObject;
                // console.debug("getTranslations", translationDataFromAPI);
                addTranslation(translationTransform(translationDataFromAPI, enabledLanguages));
            };

            getTranslations()
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [isDataLoaded, initialize, addTranslation]);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />

            {isLoading ? (
                <FullPageLoading />
            ) : (
                <Router>
                    <Switch>
                        <Route path={getAccountsURL()} component={AccountsPage} />
                        <Route path={getUnauthorizedURL()} component={UnauthorizedPage} />
                        <Route path={getSignupURL()} component={SignupPage} />
                        <Route path={getLoginURL()} component={LoginPage} />
                        <Route path={getResetRequestURL()} component={ResetPasswordRequestPage} />
                        <Route
                            path={getResetPasswordMailSentURL()}
                            component={ResetPasswordMailSentPage}
                        />
                        <Route path={getResetPasswordURL()} component={ResetPasswordPage} />
                        <Route path="/" component={AccountsPage} />
                    </Switch>
                </Router>
            )}
        </ThemeProvider>
    );
};

export default withLocalize(App);
