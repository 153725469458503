import querystring, { ParsedUrlQueryInput } from "querystring";
import { PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH } from "./constants";

export const urlQueryToObject = (query?: string) => {
    query = query || window.location.search;
    query = query.replace("?", "");
    if (query.trim().length === 0) {
        return {};
    }

    try {
        return JSON.parse(
            '{"' +
                decodeURI(query.replace("?", "").replace(/&/g, '","').replace(/=/g, '":"')) +
                '"}'
        );
    } catch (error) {
        console.error(error);
        return {};
    }
};

export const objectToQueryString = (
    queryObject?: ParsedUrlQueryInput | object,
    encodeURIComponentEnabled = true
): string => {
    if (!queryObject) {
        return "";
    }

    return encodeURIComponentEnabled
        ? querystring.stringify(queryObject as ParsedUrlQueryInput)
        : Object.entries(queryObject).reduce((acc, [key, value]) => {
              return acc + (acc.length > 0 ? "&" : "") + `${key}=${String(value)}`;
          }, "");
};

export function checkPasswordLength(value: string | undefined): boolean {
    return Boolean(
        value && value.length >= PASSWORD_MIN_LENGTH && value.length <= PASSWORD_MAX_LENGTH
    );
}
