import React from "react";
import { Grid, CircularProgress, createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { CircularProgressProps } from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        loading: {
            color: theme.palette.primary.main,
        },
    })
);

const FullPageLoading = (props: CircularProgressProps) => {
    const classes = useStyles();

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: "100vh" }}
        >
            <Grid item xs={4}>
                <CircularProgress
                    className={classes.loading}
                    color="primary"
                    size={"5rem"}
                    {...props}
                />
            </Grid>
        </Grid>
    );
};

export default FullPageLoading;
