export enum ClientSourceType {
    APP_PATIENT_ANDROID = "app_patient_android",
    APP_PATIENT_IOS = "app_patient_ios",
    WEB_SPECIALIST = "web_specialist",
}

export const appSourceTypes = [
    ClientSourceType.APP_PATIENT_ANDROID,
    ClientSourceType.APP_PATIENT_IOS,
];
