import { ImFineError } from "@im-fine/ui-libs";
import { EventEmitter } from "events";
import { objectToQueryString } from "../util/utils";
import { AuthorizeRequest } from "./objects/AuthorizeRequest";
import { AuthorizeURLResponse } from "./objects/AuthorizeURLResponse";
import { default as LoginResponse, default as OAuth2LoginResponse } from "./objects/OAuth2Login";
import OAuth2TokenInfo from "./objects/OAuth2TokenInfo";
import { ProviderType } from "./objects/ProviderType";
import { User } from "./objects/User";

export const AUTHENTICATION_URI = "/authentication";

export type ErrorHandler = (error: ImFineError) => boolean | void;

export interface AuthenticationClientOptions {
    apiRootURL: string;
    clientID: string;
    clientSecret?: string;
    autoRefreshClientCredentials?: boolean;
    onError?: ErrorHandler;
    userTimezone?: string;
}

export interface FetchParams extends RequestInit {
    bodyObject?: Object | null;
    eventName?: string;
    encodeBodyInFormData?: boolean;
    includeClientAuth?: boolean;
    basicAuth?: {
        username: string;
        password: string;
    };
}

type HeadersType = { [key: string]: string };

export class AuthenticationClient extends EventEmitter {
    protected _apiRootURL: string = "";
    protected _oAuth2Login?: OAuth2LoginResponse;
    protected _clientID: string;
    protected _clientSecret?: string;
    protected _isAuthenticated: boolean = false;
    protected _autoRefreshClientCredentials: boolean = false;
    protected _onError?: ErrorHandler;
    protected _refreshTimeout?: NodeJS.Timeout;
    protected _userTimezone?: string;

    constructor({
        apiRootURL,
        clientID,
        clientSecret,
        autoRefreshClientCredentials = true,
        onError,
        userTimezone,
    }: AuthenticationClientOptions) {
        super();
        this._apiRootURL = apiRootURL.trim();
        if (this._apiRootURL.endsWith("/")) {
            this._apiRootURL = this._apiRootURL.substr(0, this._apiRootURL.length - 1);
        }
        this._clientID = clientID;
        this._clientSecret = clientSecret;
        this._autoRefreshClientCredentials = autoRefreshClientCredentials;
        this._onError = onError;
        this._userTimezone = userTimezone;
    }

    get accessToken(): string | undefined {
        if (!this._oAuth2Login) {
            return undefined;
        }

        return this._oAuth2Login.access_token;
    }

    get isAuthenticated() {
        return this._isAuthenticated;
    }

    set isAuthenticated(value: boolean) {
        if (value === this._isAuthenticated) {
            return;
        }

        this._isAuthenticated = value;
        if (value) {
            this.emit(AuthenticationClient.EVENT_AUTHENTICATED);
        } else {
            this.emit(AuthenticationClient.EVENT_NOT_AUTHENTICATED);
        }
    }

    hasAuthToken() {
        return typeof this.accessToken === "string" && this.accessToken.length > 0;
    }

    _getAuthorizationHeaderValue(token: string): string {
        return `Bearer ${token}`;
    }

    _getDefaultHeaders() {
        const headers: HeadersType = {
            "Content-Type": "application/json",
        };

        if (this.hasAuthToken()) {
            headers["Authorization"] = this._getAuthorizationHeaderValue(this.accessToken || "");
        }

        if (this._userTimezone) {
            headers["X-User-Timezone"] = this._userTimezone;
        }

        return headers;
    }

    async fetch(
        strURIPath: string,
        {
            method = "GET",
            headers = {},
            bodyObject = null,
            eventName,
            encodeBodyInFormData,
            includeClientAuth,
            basicAuth,
            ...rest
        }: FetchParams = {}
    ) {
        let body;
        if (bodyObject) {
            if (encodeBodyInFormData) {
                const formData = new FormData();
                for (const [key, value] of Object.entries(bodyObject)) {
                    if (value !== undefined) {
                        formData.append(key, value);
                    }
                }

                body = formData; //.getBuffer();
            } else {
                body = JSON.stringify(bodyObject);
            }
        }

        if (includeClientAuth && !basicAuth) {
            basicAuth = {
                username: this._clientID,
                password: this._clientSecret || "",
            };
        }

        const objOptions = {
            method,
            credentials: "include",
            headers: Object.assign({}, this._getDefaultHeaders(), headers),
            body: rest.body || body,
            ...rest,
        };

        if (encodeBodyInFormData) {
            delete (objOptions.headers as HeadersType)["Content-Type"];
        }

        if (basicAuth) {
            (objOptions.headers as HeadersType)["Authorization"] = `Basic ${Buffer.from(
                this._clientID + ":" + this._clientSecret || ""
            ).toString("base64")}`;
        }

        if (!strURIPath.startsWith("/")) {
            strURIPath = `/${strURIPath}`;
        }

        const strRequestURL = encodeURI(`${this._apiRootURL}${strURIPath}`);

        const response = await fetch(strRequestURL, objOptions as any);
        if (response.status >= 200 && response.status < 400) {
            const parsedResponse = await response.json();

            if (eventName) {
                this.emit(eventName, parsedResponse);
            }

            return parsedResponse;
        }

        let strErrorMessage = `AuthenticationError: Status ${response.status} - "${response.statusText}"`;
        const strBodyText = await response.text();
        strErrorMessage += `\nBody: ${strBodyText}`;
        const customError = new ImFineError({
            statusCode: response.status,
            message: strErrorMessage,
            errorCode: response.status,
        });

        try {
            const objBody = JSON.parse(strBodyText);
            console.error(objBody);

            customError.error = objBody.error ?? customError.error;
            customError.errorCode = objBody.errorCode ?? customError.errorCode;

            customError.message =
                objBody.message ?? objBody.error_description ?? customError.message;
        } catch (error) {
            console.error("Not a JSON body on response.");
        }

        let shouldThrowError = true;

        if (this._onError) {
            const errorHandlerResponse = this._onError(customError);
            shouldThrowError = errorHandlerResponse !== false;
        } else {
            this.emit("error", customError.toPrivateObject());
        }

        if (shouldThrowError) {
            throw customError;
        }
    }

    async post(strURIPath: string, options: FetchParams = {}) {
        return this.fetch(strURIPath, { method: "POST", ...options });
    }

    async get(strURIPath: string, options: FetchParams = {}) {
        return this.fetch(strURIPath, { method: "GET", ...options });
    }

    async put(strURIPath: string, options: FetchParams = {}) {
        return this.fetch(strURIPath, { method: "PUT", ...options });
    }

    async delete(strURIPath: string, options: FetchParams = {}) {
        return this.fetch(strURIPath, { method: "DELETE", ...options });
    }

    async checkOwnToken(): Promise<OAuth2TokenInfo> {
        return this.get("/token/check");
    }

    async checkAccessToken(accessToken: string): Promise<OAuth2TokenInfo> {
        return this.get("/token/check", {
            headers: {
                Authorization: this._getAuthorizationHeaderValue(accessToken),
            },
        });
    }

    async getAuthenticatedUser(): Promise<User> {
        return this.get("/authentication/user");
    }

    async getAuthenticatedUserForToken(accessToken: string): Promise<User> {
        return this.get("/authentication/user", {
            headers: {
                Authorization: this._getAuthorizationHeaderValue(accessToken),
            },
        });
    }

    async getAuthorizationURLForProvider(
        providerType: ProviderType,
        request: AuthorizeRequest
    ): Promise<AuthorizeURLResponse> {
        if (!request.redirectURI || !request.userRole) {
            throw new Error("redirectURI and userRole are required.");
        }
        return this.get(
            `/authentication/url/authorize/${providerType}?${objectToQueryString({
                redirect_uri: request.redirectURI,
                state: request.state,
                scope: request.scopes?.join(" "),
                user_role: request.userRole,
            })}`
        );
    }

    async refreshToken(refreshToken?: string): Promise<OAuth2LoginResponse> {
        this._oAuth2Login = (await this.post("/token/refresh", {
            bodyObject: {
                refreshToken,
            },
            includeClientAuth: true,
        })) as LoginResponse;
        if (this._autoRefreshClientCredentials) {
            this.startAutoRefresh();
        }

        if (!this._isAuthenticated) {
            this._isAuthenticated = true;
            this.emit(AuthenticationClient.EVENT_AUTHENTICATED, this._oAuth2Login);
        }

        return this._oAuth2Login;
    }

    async login(email: string, password: string, scope?: string): Promise<OAuth2LoginResponse> {
        const loginResponse = (await this.post("/login", {
            bodyObject: {
                email,
                password,
                scope,
            },
            includeClientAuth: true,
        })) as LoginResponse;

        if (!loginResponse.access_token) {
            throw new Error("access_token property not set in response from register.");
        }

        this._oAuth2Login = loginResponse;

        this._isAuthenticated = true;
        this.emit(AuthenticationClient.EVENT_AUTHENTICATED, loginResponse);

        if (this._autoRefreshClientCredentials) {
            console.debug("_autoRefreshClientCredentials");
            this.startAutoRefresh();
        }

        return loginResponse;
    }

    async loginOwnCredentials(scope?: string): Promise<OAuth2LoginResponse> {
        const loginResponse = (await this.post(`${AUTHENTICATION_URI}/oauth/token`, {
            bodyObject: {
                grant_type: "client_credentials",
                scope,
            },
            encodeBodyInFormData: true,
            includeClientAuth: true,
        })) as LoginResponse;

        if (!loginResponse.access_token) {
            throw new Error("access_token property not set in response from register.");
        }

        this._oAuth2Login = loginResponse;

        return loginResponse;
    }

    startAutoRefresh() {
        if (!this._oAuth2Login) {
            throw new Error("Tried to start auto refresh, but the OAuth2 login data was not set.");
        }

        if (this._refreshTimeout) {
            clearTimeout(this._refreshTimeout);
        }

        const refreshTimeoutSeconds =
            this._oAuth2Login.expires_in -
            AuthenticationClient.AUTO_REFRESH_TOKENS_EXPIRING_IN_SECONDS;

        this._refreshTimeout = setTimeout(() => {
            if (this.isAuthenticated && this._oAuth2Login) {
                this.refreshToken().catch((error) => console.error(error));
            }
        }, refreshTimeoutSeconds * 1000);

        console.debug(
            `Auto refresh the client credentials enabled for clientID "${this._clientID}" with a timeout of ${refreshTimeoutSeconds} seconds.`
        );
    }

    async logout() {
        return this.post("/logout");
    }

    async logoutOwnCredentials() {
        this._oAuth2Login = undefined;
        this.isAuthenticated = false;
    }

    static readonly EVENT_AUTHENTICATED: string = "authenticated";
    static readonly EVENT_NOT_AUTHENTICATED: string = "not_authenticated";

    static readonly AUTO_REFRESH_TOKENS_EXPIRING_IN_SECONDS = 20;
}
