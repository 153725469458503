import { UIErrorMessageType } from "@im-fine/ui-libs";
import {
    Box,
    Button,
    Container,
    FormControl,
    Grid,
    Paper,
    TextField,
    Theme,
    Tooltip,
    Typography,
} from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import { createStyles, makeStyles } from "@material-ui/styles";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { LocalizeContextProps, Translate, withLocalize } from "react-localize-redux";
import { StaticContext } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import client from "../api/client";
import { appSourceTypes, ClientSourceType } from "../api/objects/ClientSourceType";
import { enabledLanguages, getDefaultLanguage, LanguageType } from "../api/objects/language";
import PasswordResetSVG from "../assets/images/undraw_authentication_fsn5.svg";
import { Copyright } from "../components/Footer";
import { LanguageChanger } from "../components/LanguageChanger";
import { Top } from "../components/Top";
import {
    DEFAULT_REDIRECT_URL,
    PASSWORD_MAX_LENGTH,
    PASSWORD_MIN_LENGTH,
    QUERY_PARAM_ACCESS_TOKEN,
    QUERY_PARAM_EMAIL,
    QUERY_PARAM_ERROR_ID,
    QUERY_PARAM_LANGUAGE,
    QUERY_PARAM_REDIRECT_URL,
    QUERY_PARAM_SOURCE,
} from "../util/constants";
import {
    getErrorFromQueryString,
    getLanguageFromQueryString,
    getLoginURL,
    getUnauthorizedURL,
    URLParams,
} from "../util/routes";
import { checkPasswordLength } from "../util/utils";

export interface ResetPasswordPageProps {}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            body: {
                backgroundColor: theme.palette.common.white,
            },
        },
        errorText: {
            verticalAlign: "bottom",
            marginLeft: theme.spacing(0.5),
        },
        paper: {
            marginTop: theme.spacing(8),
            display: "flex",
            padding: theme.spacing(5),

            flexDirection: "column",
            alignItems: "center",
        },
        form: {
            width: "100%", // Fix IE 11 issue.
            marginTop: theme.spacing(1),
        },
        submit: {
            margin: theme.spacing(3, 0, 2),
        },
        image: {
            width: "100%",
            maxWidth: "15rem",
            margin: theme.spacing(1),
        },
        cancelLink: {
            color: theme.palette.primary.main,
            cursor: "pointer",
        },
        languageDropdown: {},
    })
);

function ResetPasswordPage({
    translate,
    activeLanguage,
    ...props
}: ResetPasswordPageProps & RouteComponentProps<any, StaticContext, any> & LocalizeContextProps) {
    const classes = useStyles();
    const urlParams = queryString.parse(window.location.search);

    const [accessToken] = useState(urlParams[QUERY_PARAM_ACCESS_TOKEN] as string);

    const redirectURL = decodeURIComponent(
        (urlParams[QUERY_PARAM_REDIRECT_URL] as string) ?? DEFAULT_REDIRECT_URL
    );

    const clientSource: ClientSourceType | undefined = urlParams[QUERY_PARAM_SOURCE]
        ? (decodeURIComponent(urlParams[QUERY_PARAM_SOURCE] as string) as ClientSourceType)
        : undefined;

    const [error, setError] = useState<UIErrorMessageType>(getErrorFromQueryString(translate));
    const [email, setEmail] = useState<string | undefined>(undefined);
    const [errorEmail, setErrorEmail] = useState<UIErrorMessageType>(undefined);
    const [errorNewPassword, setErrorNewPassword] = useState<UIErrorMessageType>(undefined);
    const [errorConfirmPassword, setErrorConfirmPassword] = useState<UIErrorMessageType>(undefined);
    const [language, setLanguage] = useState(getLanguageFromQueryString() ?? getDefaultLanguage());
    const [newPassword, setNewPassword] = useState<string | undefined>(undefined);
    const [confirmPassword, setConfirmPassword] = useState<string | undefined>(undefined);
    const [isLoading, setLoading] = useState(false);

    const isAppClientSource = React.useMemo(
        () => Boolean(clientSource && appSourceTypes.includes(clientSource)),
        [clientSource]
    );

    useEffect(() => {
        if (confirmPassword !== newPassword && confirmPassword) {
            setErrorConfirmPassword(translate("confirm_password_no_match") as string);
        } else {
            setErrorConfirmPassword(undefined);
        }
    }, [newPassword, confirmPassword, translate]);

    useEffect(() => {
        if (!accessToken) {
            setError(translate("password_reset_no_access_token") as string);
            window.location.assign(
                getUnauthorizedURL({
                    [QUERY_PARAM_ERROR_ID]: "password_reset_no_access_token",
                    ...urlParams,
                } as URLParams)
            );
        }
    }, [accessToken, urlParams, translate]);

    useEffect(() => {
        if (!email && accessToken && !isLoading) {
            setLoading(true);

            client
                .getAuthenticatedUserForToken(accessToken)
                .then((user) => {
                    if (!email) {
                        setEmail(user.email);
                    }
                    if (user.language && !urlParams[QUERY_PARAM_LANGUAGE]) {
                        setLanguage(user.language);
                    }
                    setLoading(false);
                })
                .catch((error) => setError(error.message));
        }
    }, [email, accessToken, isLoading, urlParams]);

    const handleNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        setErrorNewPassword(undefined);
        setNewPassword(newValue);
        if (!checkPasswordLength(newValue)) {
            setErrorNewPassword(
                translate("incorrect_password_length", {
                    min: PASSWORD_MIN_LENGTH,
                    max: PASSWORD_MAX_LENGTH,
                }) as string
            );
        }
    };

    const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(event.target.value);
        setErrorConfirmPassword(undefined);
    };

    const handleCancelRequestClick = async (event: any) => {
        event.preventDefault();
        if (isLoading) {
            return false;
        }

        try {
            setLoading(true);
            await client.cancelPasswordReset(accessToken);
            props.history.push(getLoginURL(linkParams), {});
            setLoading(false);
        } catch (error) {
            setError(error);
            console.error(error);
        }
    };

    const clearErrors = () => {
        setErrorEmail(undefined);
        setErrorNewPassword(undefined);
        setErrorConfirmPassword(undefined);
        setError(undefined);
    };

    const handleLanguageChange = (newLanguage: LanguageType) => {
        setLanguage(newLanguage);
        props.setActiveLanguage(newLanguage);
    };

    const callResetPassword = async () => {
        try {
            setLoading(true);
            clearErrors();
            if (newPassword === undefined || newPassword.length === 0) {
                setErrorNewPassword("Empty password.");
                return;
            }
            if (
                confirmPassword === undefined ||
                confirmPassword.length === 0 ||
                confirmPassword !== newPassword
            ) {
                setErrorNewPassword(translate("password_reset_no_access_token") as string);
                return;
            }

            const resetUser = await client.resetPassword(accessToken, newPassword);

            if (!isAppClientSource) {
                let loginEmail = email ?? resetUser.email;
                if (!loginEmail) {
                    setError("No email set for login");
                    return;
                }
                await client.login(loginEmail, newPassword);
            }

            window.location.assign(redirectURL);
        } catch (error) {
            console.error(error);
            // if (
            //     typeof error.message === "string" &&
            //     error.message.toLowerCase().includes("email")
            // ) {
            //     setErrorEmail(error.message);
            // } else if (
            //     typeof error.message === "string" &&
            //     error.message.toLowerCase().includes("password")
            // ) {
            //     setErrorNewPassword(error.message);
            // } else {
            setError(error.message);
            // }
        } finally {
            setLoading(false);
        }
    };

    const handleResetPasswordClick = async (event: any) => {
        event.preventDefault();
        return await callResetPassword();
    };

    const linkParams: { [key: string]: string } = {
        ...urlParams,
        [QUERY_PARAM_LANGUAGE]: language,
    };

    if (email && email.trim().length > 0) {
        linkParams[QUERY_PARAM_EMAIL] = encodeURIComponent(email);
    }

    if (redirectURL !== DEFAULT_REDIRECT_URL) {
        linkParams[QUERY_PARAM_REDIRECT_URL] = redirectURL;
    }

    return (
        <Container maxWidth="sm">
            <Top />
            <Paper elevation={3} square={false} className={classes.paper}>
                <img alt="mail" className={classes.image} src={PasswordResetSVG} />
                <Typography component="h1" variant="h5">
                    <Translate id="reset_password_title" />
                </Typography>
                <Grid container justify="center" direction="column" alignContent="center">
                    <LanguageChanger
                        id="language"
                        enabledLanguages={enabledLanguages}
                        selectedLanguage={language}
                        onChange={handleLanguageChange}
                    />
                </Grid>
                <Typography component="h4" color="error" hidden={error === undefined}>
                    <ErrorIcon />
                    <Box component="span" className={classes.errorText}>
                        {error}
                    </Box>
                </Typography>
                <form className={classes.form} noValidate>
                    <Tooltip title={translate("email_change_not_allowed")} arrow>
                        <TextField
                            error={errorEmail !== undefined}
                            variant="standard"
                            margin="normal"
                            helperText={errorEmail || ""}
                            required
                            fullWidth
                            value={email ?? ""}
                            id="email"
                            label={translate("email_address") as string}
                            name="email"
                            autoComplete="email"
                            autoFocus
                            disabled
                            InputProps={{
                                inputProps: {
                                    style: {
                                        cursor: "not-allowed",
                                    },
                                },
                            }}
                        />
                    </Tooltip>
                    <FormControl fullWidth className={classes.form}>
                        <TextField
                            helperText={errorNewPassword || ""}
                            variant="standard"
                            margin="normal"
                            required
                            label={translate("new_password") as string}
                            fullWidth
                            error={errorNewPassword !== undefined}
                            name="newPassword"
                            type="password"
                            id="new_password"
                            autoComplete="new-password"
                            onChange={handleNewPasswordChange}
                            disabled={isLoading}
                            value={newPassword ?? ""}
                        />
                    </FormControl>
                    <FormControl fullWidth className={classes.form}>
                        <TextField
                            helperText={errorConfirmPassword || ""}
                            variant="standard"
                            margin="normal"
                            required
                            label={translate("confirm_password") as string}
                            fullWidth
                            error={errorConfirmPassword !== undefined}
                            name="confirmPassword"
                            type="password"
                            id="confirm_password"
                            autoComplete="new-password"
                            onChange={handleConfirmPasswordChange}
                            disabled={isLoading}
                            value={confirmPassword ?? ""}
                        />
                    </FormControl>
                    <Button
                        disabled={isLoading}
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleResetPasswordClick}
                    >
                        <Translate id="reset_password_submit" />
                    </Button>
                    <Grid container>
                        {!isAppClientSource && (
                            <Grid item xs={12}>
                                <Typography
                                    className={classes.cancelLink}
                                    onClick={handleCancelRequestClick}
                                    variant="body2"
                                >
                                    <Translate id="reset_password_cancel" />
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </form>
            </Paper>
            <Box mt={2}>
                <Copyright />
            </Box>
        </Container>
    );
}

export default withLocalize(ResetPasswordPage);
