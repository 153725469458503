import { Box, Button, Menu, MenuItem } from "@material-ui/core";
import * as React from "react";
import { Translate } from "react-localize-redux";
import { Gender } from "../api/objects/User";
import GenderFemaleSVG from "../assets/images/gender_female.svg";
import GenderMaleSVG from "../assets/images/gender_male.svg";
import GenderUnspecifiedSVG from "../assets/images/gender_unspecified.svg";

export interface GenderChangerProps {
    selectedGender: Gender;
    enabledGenders: Gender[];
    linkClassNames?: string;
    dropdownClassNames?: string;
    containerClassNames?: string;
    id?: string;
    title?: string;
    onChange: (newLanguage: Gender) => void;
}

export const mapGenderToIconElement = (gender: Gender) => {
    let icon = GenderUnspecifiedSVG;
    switch (gender) {
        case Gender.FEMALE:
        case Gender.TRANSGENDER_FEMALE:
            icon = GenderFemaleSVG;
            break;
        case Gender.MALE:
        case Gender.TRANSGENDER_MALE:
            icon = GenderMaleSVG;
            break;
        case Gender.UNSPECIFIED:
            icon = GenderUnspecifiedSVG;
            break;
    }
    return (
        <img
            alt={gender}
            src={icon}
            style={{
                height: "1rem",
                width: "1rem",
            }}
        />
    );
};

export const GenderChanger = (props: GenderChangerProps) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const id = props.id || "gender-changer__dropdown";
    const linkClassNames = props.linkClassNames || "";
    const dropdownClassNames = props.dropdownClassNames || "";
    const containerClassNames = props.containerClassNames || "";
    const title = props.title || "Change language"; //TODO #translate

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const genderItems = props.enabledGenders.map((gender) => (
        <MenuItem
            key={gender}
            onClick={() => {
                props.onChange(gender);
                handleClose();
            }}
        >
            {mapGenderToIconElement(gender)}
            <Box ml={1} component="span">
                <Translate id={`gender_${gender}`}>Unspecified</Translate>
            </Box>
        </MenuItem>
    ));

    return (
        <div className={containerClassNames}>
            <Button
                className={linkClassNames}
                aria-controls={id}
                aria-haspopup="true"
                title={title}
                onClick={handleClick}
            >
                {mapGenderToIconElement(props.selectedGender)}
                <Box ml={1} component="span">
                    <Translate id={`gender_${props.selectedGender}`}>
                        props.selectedGender
                    </Translate>
                </Box>
            </Button>
            <Menu
                id={id}
                anchorEl={anchorEl}
                className={dropdownClassNames}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {genderItems}
            </Menu>
        </div>
    );
};
