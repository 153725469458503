import { createStyles, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import TopSVG from "../assets/images/top-no-bullets.svg";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        icon: {
            zIndex: -999,
            width: "30vw",
            position: "absolute",
            top: 0,
            right: 0,
        },
    })
);

export const Top = () => {
    const classes = useStyles();
    return <img alt="top" className={classes.icon} src={TopSVG} />;
};
