import { AuthenticationClient, AuthenticationClientOptions } from "../AuthenticationClient";
import { Application } from "./objects/Application";
import { TranslationExportReturnType } from "./objects/Translation";

export enum TranslationExportType {
    BY_LANGUAGE = "by_language",
    BY_KEY = "by_key",
    BY_LANGUAGE_OBJECT = "by_language_object",
    BY_KEY_OBJECT = "by_key_object",
}

export class APITranslation extends AuthenticationClient {
    constructor(
        public applicationName: string,
        authenticationClientOptions: AuthenticationClientOptions
    ) {
        super(authenticationClientOptions);
        if (!applicationName) {
            throw new Error("Invalid application name");
        }
    }

    async getTranslations(
        exportType = TranslationExportType.BY_LANGUAGE_OBJECT
    ): Promise<TranslationExportReturnType> {
        const applicationData = (await this.get(
            `/applications/${this.applicationName}?exportType=${exportType}`
        )) as Application;
        if (!applicationData.translations) {
            throw new Error(`Application ${this.applicationName} does not have any translations.`);
        }

        return applicationData.translations;
    }
}
