export const getEnvVariable = (envVarName: string): string => {
    if (
        typeof process.env[envVarName] !== "string" ||
        (process.env[envVarName] as string).trim().length === 0
    ) {
        throw new Error(`Invalid process.env.${envVarName}`);
    }

    return (process.env[envVarName] as string).trim();
};

export const getEnvURL = (envURL: string): string => {
    let returnURL;
    const url = new URL(envURL);

    if (process.env.REACT_APP_API_SAME_ORIGIN === "true") {
        returnURL = `${window.location.origin}${url.pathname}${url.search}${url.hash}`;
    } else if (process.env.REACT_APP_API_SAME_HOST === "true") {
        returnURL = `${url.protocol}//${window.location.hostname}:${url.port}${url.pathname}${url.search}${url.hash}`;
    } else {
        returnURL = envURL.trim();
    }

    if (!returnURL) {
        throw new Error(`Env URL cannot be parsed: ${envURL}.`);
    }

    return returnURL;
};

export const getRootAPI = (): string => {
    if (
        process.env.REACT_APP_API_URL === undefined ||
        process.env.REACT_APP_API_URL.trim().length === 0
    ) {
        throw new Error("Invalid process.env.REACT_APP_API_URL");
    }

    let rootAPI = getEnvURL(process.env.REACT_APP_API_URL);

    if (rootAPI.endsWith("/")) {
        rootAPI = rootAPI.substring(0, rootAPI.length - 1);
    }

    return rootAPI;
};
