import {
    Box,
    Button,
    CircularProgress,
    Container,
    createStyles,
    Grid,
    Link,
    makeStyles,
    Paper,
    Theme,
    Typography,
} from "@material-ui/core";
import clsx from "clsx";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { LocalizeContextProps, Translate, withLocalize } from "react-localize-redux";
import { StaticContext } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import client from "../api/client";
import SecuritySVG from "../assets/images/undraw_security_o890.svg";
import { Copyright } from "../components/Footer";
import OrDividerRow from "../components/OrDividerRow";
import { Top } from "../components/Top";
import StyledLink from "../navigation/StyledLink";
import { DEFAULT_REDIRECT_URL, QUERY_PARAM_REDIRECT_URL } from "../util/constants";
import { getErrorFromQueryString, getLoginURL, getSignupURL } from "../util/routes";

export interface UnauthorizedPageProps {}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            body: {
                backgroundColor: theme.palette.common.white,
            },
        },
        errorText: {
            verticalAlign: "bottom",
            marginLeft: theme.spacing(0.5),
        },
        paper: {
            marginTop: theme.spacing(8),
            display: "flex",
            padding: theme.spacing(5),

            flexDirection: "column",
            alignItems: "center",
        },
        icon: {
            height: "10rem",
            width: "100%",
            margin: theme.spacing(1),
        },
        avatar: {
            height: 50,
            width: 50,
            margin: theme.spacing(1),
            backgroundColor: theme.palette.error.main,
        },
        row: {
            width: "100%", // Fix IE 11 issue.
            marginTop: theme.spacing(1),
            textAlign: "center",
        },
        callToAction: {
            width: "100%", // Fix IE 11 issue.
            textAlign: "center",
            marginTop: theme.spacing(3),
        },
        loginButton: {
            margin: theme.spacing(1, 0, 2),
        },
        progress: {
            margin: theme.spacing(2),
        },
        title: {
            fontWeight: theme.typography.fontWeightBold,
        },
        textCenter: {
            textAlign: "center",
        },
    })
);

function UnauthorizedPage(
    props: UnauthorizedPageProps &
        RouteComponentProps<any, StaticContext, any> &
        LocalizeContextProps
) {
    const classes = useStyles(props);
    const urlParams = queryString.parse(window.location.search);

    const redirectURL = (urlParams[QUERY_PARAM_REDIRECT_URL] as string) || DEFAULT_REDIRECT_URL;
    const error =
        getErrorFromQueryString(props.translate) ??
        (props.translate("unauthorized_default_message") as string);
    const [authenticatedEmail, setAuthenticatedEmail] = useState<string>();
    const [checkedToken, setCheckedToken] = useState(false);
    const [isLoading, setLoading] = useState(false);
    // const [language, setLanguage] = useState(/*getDefaultLanguage()*/ getBrowserLanguage());

    const linkParams = {
        ...urlParams,
        [QUERY_PARAM_REDIRECT_URL]: encodeURIComponent(redirectURL),
    };

    // const handleLanguageChange = (newLanguage: LanguageType) => {
    //     setLanguage(newLanguage);
    //     props.setActiveLanguage(newLanguage);
    // };

    useEffect(() => {
        if (!checkedToken && !isLoading) {
            setLoading(true);
            client
                .getAuthenticatedUser()
                .then((user) => {
                    setAuthenticatedEmail(user.email);
                    setCheckedToken(true);
                })
                .catch((error) => {
                    // console.error(error);
                    setAuthenticatedEmail(undefined);
                    setCheckedToken(true);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [checkedToken, isLoading]);

    return (
        <Container maxWidth="sm">
            <Top />
            <Paper elevation={3} square={false} className={classes.paper}>
                <img alt="Security" className={classes.icon} src={SecuritySVG} />
                <Typography
                    className={clsx(classes.row, classes.title)}
                    color="error"
                    component="h2"
                    variant="h5"
                >
                    <Translate id="unauthorized_title" />
                </Typography>
                <Typography
                    className={classes.row}
                    component="h4"
                    color="error"
                    hidden={error === undefined}
                >
                    <Box component="span" className={classes.errorText}>
                        {error}
                    </Box>
                </Typography>

                {/* <Grid container justify="center" direction="column" alignContent="center">
					<LanguageChanger
						id="language"
						enabledLanguages={enabledLanguages}
						selectedLanguage={language}
						onChange={handleLanguageChange}
					/>
				</Grid> */}
                {isLoading ? (
                    <CircularProgress className={classes.progress} />
                ) : (
                    <Box className={classes.callToAction}>
                        {authenticatedEmail && (
                            <Typography color="textSecondary">
                                <Translate
                                    id="authenticated_as_email"
                                    data={{ email: <b>{authenticatedEmail}</b> }}
                                />
                            </Typography>
                        )}
                        <Grid container direction="column" justify="center" alignContent="center">
                            <Grid item xs={12}>
                                <StyledLink to={getLoginURL(linkParams)}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        className={classes.loginButton}
                                    >
                                        {authenticatedEmail ? (
                                            <Translate id="login_another_user" />
                                        ) : (
                                            <Translate id="login_submit" />
                                        )}
                                    </Button>
                                </StyledLink>
                            </Grid>
                            {authenticatedEmail ? (
                                <>
                                    <Grid item>
                                        <OrDividerRow />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Link href={redirectURL}>
                                            <Translate id="unauthorized_try_again" />
                                        </Link>
                                    </Grid>
                                </>
                            ) : (
                                <Grid item xs={12}>
                                    <StyledLink to={getSignupURL(linkParams)}>
                                        <Translate id="login_signup_link" />
                                    </StyledLink>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                )}
            </Paper>
            <Box mt={2}>
                <Copyright />
            </Box>
        </Container>
    );
}

export default withLocalize(UnauthorizedPage);
