import { APITranslation } from "./translation/APITranslation";
import { getRootAPI } from "../util/env";

if (!process.env.REACT_APP_TRANSLATION_APPLICATION) {
    throw new Error("process.env.REACT_APP_TRANSLATION_APPLICATION is undefined");
}

export const translationApplicationName = process.env.REACT_APP_TRANSLATION_APPLICATION;

const translationClient = new APITranslation(translationApplicationName, {
    apiRootURL: `${getRootAPI()}/translation`,
    clientID: process.env.REACT_APP_API_CLIENT_ID!!,
    clientSecret: process.env.REACT_APP_API_CLIENT_SECRET!!,
    autoRefreshClientCredentials: true,
});

translationClient.on("error", console.error);

export default translationClient;
