import { getEnvURL } from "./env";

if (
    process.env.REACT_APP_DEFAULT_REDIRECT_URL === undefined ||
    process.env.REACT_APP_DEFAULT_REDIRECT_URL.trim().length === 0
) {
    throw new Error("Invalid process.env.REACT_APP_DEFAULT_REDIRECT_URL");
}

export const DEFAULT_REDIRECT_URL = getEnvURL(process.env.REACT_APP_DEFAULT_REDIRECT_URL);

export const QUERY_PARAM_EMAIL: string = "email";
export const QUERY_PARAM_REDIRECT_URL = "r";
export const QUERY_PARAM_SOURCE = "source";
export const QUERY_PARAM_ERROR = "error";
export const QUERY_PARAM_ERROR_ID = "error_id";
export const QUERY_PARAM_LANGUAGE = "language";
export const QUERY_PARAM_ACCESS_TOKEN = "access_token";
export const PASSWORD_MIN_LENGTH = 6;
export const PASSWORD_MAX_LENGTH = 70;

export const DEFAULT_UNAUTHORIZED_ERROR_MESSAGE = "You are not authorized to access this resource.";

console.debug("DEFAULT_REDIRECT_URL", DEFAULT_REDIRECT_URL);
