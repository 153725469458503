import {
    Button,
    ButtonProps,
    CircularProgress,
    createStyles,
    makeStyles,
    Theme,
} from "@material-ui/core";
import clsx from "clsx";
import React, { useCallback, useEffect, useState } from "react";
import { Translate } from "react-localize-redux";
import GoogleButtonIconDisabledSVG from "../assets/images/google-button/btn_google_dark_disabled_ios.svg";
import GoogleButtonIconSVG from "../assets/images/google-button/btn_google_dark_normal_ios.svg";
import { getEnvVariable } from "../util/env";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            // backgroundColor: "#4285F4",
            padding: 0,
            // paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            fontSize: 14,
            height: 40,
            justifyContent: "flex-start",
            textAlign: "center",
            borderRadius: 2,
            backgroundColor: "#ff",
        },
        icon: {},
        innerText: {
            width: "100%",
            textAlign: "center",
            marginLeft: -theme.spacing(1),
        },
        innerTextSignedIn: {
            width: "calc(100% - 46px)",
            // textAlign: "left",
            marginLeft: theme.spacing(1),
        },
        innerTextLoading: {
            marginLeft: -40,
            width: "100%",
        },
        loading: {
            marginTop: theme.spacing(1),
            color: "inherit",
        },
    })
);

export interface GoogleLoginButtonProps extends ButtonProps {
    onSuccess?: (googleUser: gapi.auth2.GoogleUser) => void;
    onError?: (reason: any) => void;
    scope?: string;
    defaultLabel?: React.ReactNode;
    isLoading?: boolean;
}

export default function GoogleLoginButton({
    onSuccess,
    onError,
    scope,
    defaultLabel,
    isLoading,
    ...props
}: GoogleLoginButtonProps) {
    const classes = useStyles();

    const buttonRef = React.useRef<HTMLButtonElement>();
    const isCanceled = React.useRef(false);
    const [auth2, setAuth2] = useState<gapi.auth2.GoogleAuth | undefined>(undefined);
    const [innerText, setInnerText] = useState<React.ReactNode>(
        defaultLabel ?? <Translate id="google_sign_in" />
    );
    const [isLoggedIn, setLoggedIn] = useState(false);

    const handleSuccess = useCallback(
        (googleUser: gapi.auth2.GoogleUser) => {
            onSuccess?.(googleUser);
            setInnerText("Continue as " + googleUser.getBasicProfile().getName());
            setLoggedIn(true);
        },
        [onSuccess]
    );

    const handleError = useCallback(
        (reason: any) => {
            onError?.(reason);
            console.error("Google sign in error: ", reason);
        },
        [onError]
    );

    useEffect(() => {
        if (isCanceled.current) {
            return;
        }

        window.gapi.load("auth2", function () {
            if (!buttonRef.current) {
                console.warn("No button ref");
                return;
            }

            // Retrieve the singleton for the GoogleAuth library and set up the client.
            const auth2Singleton = window.gapi.auth2.init({
                client_id: getEnvVariable("REACT_APP_GOOGLE_CLIENT_ID"),
                cookie_policy: "single_host_origin",
                // Request scopes in addition to 'profile' and 'email'
                scope,
            });

            auth2Singleton.attachClickHandler(buttonRef.current, {}, handleSuccess, handleError);

            setAuth2(auth2Singleton);
        });

        return () => {
            isCanceled.current = true;
        };
    }, [handleError, handleSuccess, scope]);

    return (
        <Button
            startIcon={
                <img
                    className={classes.icon}
                    src={props.disabled ? GoogleButtonIconDisabledSVG : GoogleButtonIconSVG}
                    alt="sign in"
                />
            }
            fullWidth
            variant="contained"
            color="primary"
            buttonRef={buttonRef}
            {...props}
            className={clsx(classes.button, props.className)}
        >
            <span
                className={clsx(classes.innerText, {
                    [classes.innerTextSignedIn]: isLoggedIn,
                    [classes.innerTextLoading]: isLoading,
                })}
            >
                {isLoading ? <CircularProgress size={24} className={classes.loading} /> : innerText}
            </span>
        </Button>
    );
}
