import { createStyles, makeStyles } from "@material-ui/styles";
import React from "react";
import { Link } from "react-router-dom";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        link: {
            color: theme.palette.primary.main,
            textDecoration: "none",
            "&:focus, &:hover, &:visited, &:link, &:active": {
                textDecoration: "none",
            },
        },
    })
);

export default (props: any) => {
    const classes = useStyles();
    return <Link className={classes.link} {...props} />;
}
