import moment from "moment-timezone";
import { getRootAPI } from "../util/env";
import { APIClient } from "./APIClient";
import { AuthenticationClientOptions } from "./AuthenticationClient";
import { LanguageType } from "./objects/language";

const rootAPI = getRootAPI();
console.debug("rootAPI", rootAPI);

const client = new APIClient({
    apiRootURL: `${rootAPI}`,
    clientID: process.env.REACT_APP_API_CLIENT_ID!!,
    clientSecret: process.env.REACT_APP_API_CLIENT_SECRET!!,
    autoRefreshClientCredentials: true,
    userTimezone: moment.tz.guess(),
    // onError: clientErrorHandler
} as AuthenticationClientOptions);

client.on("error", console.error);

export const enabledLanguages: LanguageType[] = ["en", "ro"];

(window as any).client = client;

export default client;
