import { createMuiTheme } from "@material-ui/core/styles";

// const mainColor = '#1FAAE6';
const mainColor = "#0070e1";
const mainContrastColor = "#FFFFFF";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: mainColor,
            contrastText: mainContrastColor,
        },
    },
});

export default theme;
