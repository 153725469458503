import { APIObjectInterface } from "./APIObjectInterface";
import { LanguageType } from "./language";

export interface User extends APIObjectInterface {
    email: string;
    emailStatus: UserEmailStatus;
    language: LanguageType;
    enabled: boolean;
    permissions: string[];
    roles: string[];
    accountExpired: boolean;
    accountLocked: boolean;
    credentialsExpired: boolean;
    gender: Gender;
}

export enum UserRole {
    ROLE_ADMIN = "ROLE_ADMIN",
    ROLE_SPECIALIST = "ROLE_SPECIALIST",
    ROLE_SPECIALIST_UNVERIFIED = "ROLE_SPECIALIST_UNVERIFIED",
    ROLE_PATIENT = "ROLE_PATIENT",
    ROLE_CONTENT_MANAGER = "ROLE_CONTENT_MANAGER",
    ROLE_TESTER = "ROLE_TESTER",
    ROLE_SUPPORT = "ROLE_SUPPORT",
}

export enum Gender {
    MALE = "male",
    FEMALE = "female",
    TRANSGENDER_MALE = "transgender_male",
    TRANSGENDER_FEMALE = "transgender_female",
    UNSPECIFIED = "unspecified",
}

export type UserEmailStatus = "verified" | "not_verified";
