import { AuthenticationClient, AUTHENTICATION_URI } from "./AuthenticationClient";
import { CreateSpecialistRequest } from "./objects/CreateSpecialistRequest";
import { GoogleLoginOrSignUpRequest } from "./objects/GoogleLoginOrSignUpRequest";
import { RegisterResponse } from "./objects/RegisterResponse";
import SendSpecialistResetPasswordRequest from "./objects/SendSpecialistResetPasswordRequest";
import { User } from "./objects/User";

export class APIClient extends AuthenticationClient {
    async createUserSpecialist(
        createUserSpecialistRequest: CreateSpecialistRequest
    ): Promise<RegisterResponse> {
        return this.post("/register/specialist", {
            includeClientAuth: true,
            bodyObject: createUserSpecialistRequest,
        });
    }

    async sendSpecialistResetPasswordMail(
        request: SendSpecialistResetPasswordRequest
    ): Promise<void> {
        await this.loginOwnCredentials();
        await this.post(`${AUTHENTICATION_URI}/user/reset-password-request`, {
            bodyObject: request,
        });
    }

    async resetPassword(accessToken: string, newPassword: string): Promise<User> {
        return this.put(`${AUTHENTICATION_URI}/user/reset-password`, {
            bodyObject: {
                password: newPassword,
            },
            headers: {
                Authorization: this._getAuthorizationHeaderValue(accessToken),
            },
        });
    }

    async cancelPasswordReset(accessToken: string): Promise<User> {
        return this.delete(`${AUTHENTICATION_URI}/user/reset-password-request`, {
            headers: {
                Authorization: this._getAuthorizationHeaderValue(accessToken),
            },
        });
    }

    async loginOrSignUpWithGoogleSpecialist(request: GoogleLoginOrSignUpRequest): Promise<any> {
        await this.loginOwnCredentials();
        return this.post(`${AUTHENTICATION_URI}/user/specialist/login/google`, {
            bodyObject: request,
        });
    }
}
